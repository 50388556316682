<template>
    <div class="errPage-container">
        <el-button icon="el-icon-arrow-left" class="pan-back-btn" @click="back">
            返回
        </el-button>
        <el-row>
            <el-col :span="12">
                <h1 class="text-jumbo text-ginormous">Oops!</h1>
                <h2>你没有权限去使用该功能</h2>
                <h6>如想使用请联系系统管理员</h6>
            </el-col>
            <el-col :span="12">
                <img
                    :src="errGif"
                    width="313"
                    height="428"
                    alt="Girl has dropped her ice cream."
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import errGif from "@/assets/img/401.gif";

export default {
    name: "Page401",
    data() {
        return {
            errGif: errGif + "?" + +new Date(),
            dialogVisible: false,
        };
    },
    methods: {
        back() {
            this.$router.push({ path: "/" });
        },
    },
};
</script>

<style lang="stylus" scoped>
.errPage-container {
  width: 800px;
  max-width: 100%;
  margin: 100px auto;
  .pan-back-btn {
    background: #008489;
    color: #fff;
    border: none!important;
  }
  .pan-gif {
    margin: 0 auto;
    display: block;
  }
  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
  }
  .list-unstyled {
    font-size: 14px;
    li {
      padding-bottom: 5px;
    }
    a {
      color: #008489;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
